<template>
  <div class="Centrepot">
    <!-- <p class="Enterprise-p"><i></i><span>碳鱼烧烤（丰台分店）</span></p> -->
     <div class="Centrepot-box">
        <div class="Centrepot-box-left">
          <div class="Centrepot-left">
            <!-- clearable 是否进行清空 -->
            <el-select v-model="value"   placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <span class="span1" @click="ting_qiyong">{{this.value==1?'停用':'启用'}}</span>
          </div>

          <div class="Centrepot_lest">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item,index) in mianbaoxie_list" :key="item.site_id">
              <a v-if="mianbaoxie_list.length-1!=index" @click="mianbaoxie(item,index)">{{item.site_name}}</a>
              <a v-else>{{item.site_name}}</a>
            </el-breadcrumb-item>
            <!-- <el-breadcrumb-item><a>活动管理</a></el-breadcrumb-item>
            <el-breadcrumb-item>活动列表</el-breadcrumb-item>
            <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
          </el-breadcrumb>
          </div>

          <div class="cangku_name">
            <!-- 仓库选中 -->
              <el-checkbox-group v-model="checkList"  @change="handleCheckedCitiesChange" >
                <el-checkbox v-for="(item,index) in qi_CangKu " :key="item.site_id"  :label="item.site_id">
                  <template>
                    <!-- <div @click.prevent="handle_ChaXunCangKu(item,index)">{{item.site_name}}({{item.child_num}})(菜:{{item.prod_num}})</div> -->
                    <div @click.prevent="handle_ChaXunCangKu(item,index)">{{item.site_name}}({{item.child_num}})(菜:{{item.prod_num}})</div>
                  </template>
                </el-checkbox>
              </el-checkbox-group>
          </div>
          <div class="Centrepot-newly" @click="add_ZengJiaCangKu">
            <div class="xinzeng_span">新增仓库</div>
          </div>
        </div>
        <div class="Centrepot-box-right">
          <div class="Centrepot-box-a">
            <div class="GongNengAnNiu">
              <a @click="drawer = true">调整仓库</a>
              <a @click="handle_ChaXunCangKu" v-if="false">查询仓库</a>
              <a @click="handle_XiuGaiCangKuMing">修改仓库</a>
              <a @click="handle_ShanChuCangKu" v-if="false">删除仓库</a>
            </div>
            <el-input type="text" v-model="product" @keyup.enter.native="searchAllCompany"  placeholder="搜索菜品名称和仓库"></el-input>
          </div>
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                label="图片"
                width="160"
                prop="image"
                >
                <!-- <template slot-scope="scope">
                  <img :src="scope.row.prod_pic" width="50px" height="50px"/>
                  </template> -->
                <template slot-scope="scope">
                  <img v-if="scope.row.prod_pic" :src="scope.row.prod_pic.split(' ')[0]" width="50px" height="50px" />
                  <div v-else>无图</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="prod_code"
                label="菜品编号"
                width="120">
              </el-table-column>
              <el-table-column
                prop="prod_name"
                label="菜品名称"
                width="160"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="site_name"
                label="所在仓库"
                width="160"
                show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div >{{scope.row.site_name?scope.row.site_name:'套餐无'}}</div>
                  </template>
              </el-table-column>
              <el-table-column
                prop="cat_name"
                label="菜品类型"
                width="160"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="spec"
                label="规格"
                width="160"
                show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div v-if="scope.row.prod_type=='2'">{{scope.row.spec}}</div>
                    <div v-if="scope.row.prod_type=='1'">{{scope.row.spec_list[0]?scope.row.spec_list[0].prod_spec:'44444'}}</div>
                  </template>
              </el-table-column>
              <el-table-column
                prop="unit"
                label="单位"
                width="180"
                show-overflow-tooltip>
              </el-table-column>
             <el-table-column
                prop="date_valid"
                label="保质期"
                width="160"
                show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div >{{scope.row.date_valid?scope.row.date_valid:'无'}}</div>
                  </template>
              </el-table-column>
            </el-table>
        </div>
        <el-drawer
          title="我是标题"
          :visible.sync="drawer"
          :with-header="false">
          <div class="adjust">
            <div class="Character-tltle">
                <span></span>
                <p>调整仓库</p>
            </div>
                <!-- 单选框 -->
                <!-- <el-radio-group v-model="checkListS" @change="handleCheckedCitiesChange_TiaoZhengCangKu">
                  <el-radio v-for="(item) in qi_CangKu " :key="item.site_id" :label="item.site_id">{{item.site_name}}</el-radio>
                </el-radio-group> -->
                <!-- 多选 -->

                <div class="Centrepot_lest">
                  <el-breadcrumb separator="/">
                  <el-breadcrumb-item v-for="(item,index) in mianbao_listCe" :key="item.site_id">
                    <a v-if="mianbao_listCe.length-1!=index" @click="mianbaoCe(item,index)">{{item.site_name}}</a>
                    <a v-else>{{item.site_name}}</a>
                  </el-breadcrumb-item>
                </el-breadcrumb>
                </div>

              <el-checkbox-group v-model="checkListTiao"  @change="handleCheckedCitiesChange_TiaoZhengCangKu" >
                <el-checkbox v-for="(item,index) in CebianCangKu " :key="item.site_id"  :label="item.site_id">
                  <template>
                    <div @click.prevent="handle_ChaXunCangKuA(item,index)">{{item.site_name}}({{item.child_num}})</div>
                  </template>
                </el-checkbox>
              </el-checkbox-group>
              <div class="admin-alter">
                <a class="admin-confirm" @click="role_confirm">确定</a>
                <a class="admin-cancel" @click="role_cancel">取消</a>
              </div>
          </div>
        </el-drawer>
<!-- 新增仓库 -->
        <el-drawer
          title="我是标题"
          :visible.sync="add_drawer"
          :with-header="false">
          <div class="adjust">
            <div class="Character-tltle">
                <span></span>
                <p>{{this.PanDuanCangKu_Shi_XiuGai==1?'新增仓库':'修改仓库名称'}}</p>
            </div>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>仓库名称
                </li>
                <li class="dd2"><input type="text" placeholder='请输入仓库名称' v-model="CaiPin_XinZeng_CangKu" ></li>
            </ul>
            <div></div>
              <div class="admin-alter">
                    <a class="admin-confirm" @click="add_NewCangKu">确定</a>
                    <a class="admin-cancel" @click="QuXiaoAdd_NewCangKu">取消</a>
                  </div>
          </div>
        </el-drawer>

    </div>
  </div>
</template>

<script>
// /query_stop_store_house_list // 查询停用仓库列表   // /query_store_house_list  // 查询仓库列表   // /insert_store_house // 新增仓库
// /update_store_house  // 修改仓库   // /store_house_active_start  // 启用仓库--批量
// /delete_store_house  // 删除仓库--批量删除 // /query_prod_of_store_house_list  // 查询仓库下的产品列表
// /batch_update_prod_store // 批量修改产品的仓库属性 // /update_prod_info // 修改产品 参数同insert_prod_info
// /get_prod_list_like // 获取产品列表 --模糊查询  // 仓库树  query_site_tree
import {
  insert_store_house, query_store_house_list, query_stop_store_house_list, store_house_active_stop, store_house_active_start,
  update_store_house, query_prod_of_store_house_list, delete_store_house, batch_update_prod_store, update_prod_info, get_prod_list_like,
  query_site_tree, query_prod_info_list
} from '@api/foodqiye'

export default {
  data() {
    return {
      PanDuanCangKu_Shi_XiuGai: '1', // 1:新增 0:修改 判断仓库是不是修改仓库名称
      CaiPin_XinZeng_CangKu: '', // 仓库名称
      indea: '1', // 当前是启用还是停用列表
      options: [{
        value: '1',
        label: '启用'
      }, {
        value: '2',
        label: '停用'
      }],
      value: '1', // 选择是停用仓库还是启用仓库
      mianbaoxie_list: [
        {
          site_name: '初始',
          site_id: ''
        }
      ],
      // 侧边栏的面包屑
      mianbao_listCe: [
        {
          site_name: '初始',
          site_id: ''
        }
      ],
      // 正在启用/停用中的仓库
      qi_CangKu: [],
      CebianCangKu: [], // 调整仓库的侧边栏中的仓库
      defaultProps: {
        children: 'children',
        label: 'site_name'
      },
      // 侧边仓库的 选中仓库的id 可以设置默认选中状态
      checkList: [], // 存放选中的仓库 id 数组形式的
      cangku_id_Str: '', // 存放选中的仓库site_id 字符串
      // 调整仓库 选中选项的
      checkListS: '', // 调整仓库 选框的选值 ok-del
      checkListTiao: [], // 调整仓库 选框的选值
      product: '',
      // 仓库内的菜品的数据
      tableData: [],
      multipleSelection: [],
      drawer: false,
      add_drawer: false,
      // 存放着选中的菜品的id 的数组
      caipinXuanZhong: [],
      // 调整仓库单选选中的仓库信息
      // radio_CangKu:{},
      radio_CangKu: {
        site_name: [],
        site_id: []
      },
      // 仓库列表
      chanpin_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      // 存放查看的仓库的id
      site_id_item: ''
    }
  },
  created() {
    // 在最开始调取仓库
    this.JiChu_CangKuXinXi()
    // 最开始全部菜品
    this.quanbu()
    this.cangkuTree()
  },
  watch: {
    // 当仓库类型改变后
    value() {
      this.JiChu_CangKuXinXi()
    },
    drawer(newval, oldval) {
      // console.log(newval,oldval);
      // 判断侧边栏消失清空单选框的选中
      if (newval == false) {
        // this.checkListS = ''
        this.checkListTiao = []
      }
    }
  },

  methods: {
    // 树状图
    cangkuTree() {
      const self = this
      query_site_tree({
        data: {
          ent_id: self.$store.state.ent_id,
          active: this.value
        }
      }).then((res) => {
        console.log('下拉树', res)
      })
    },
    // 调取查询仓库信息
    JiChu_CangKuXinXi() {
      const self = this
      // console.log(self.$store.state.ent_id);
      // let parent_id = this.indea == 1 ? this.chanpin_list[this.chanpin_list.length - 1].dept_id : null // 父id
      const PanDuanQiTing = this.value // 1启用 2停用
      if (PanDuanQiTing == 1) {
        query_store_house_list({
          data: {
          // ent_id:self.$store.state.ent_id,
            ent_id: this.$store.state.ent_id,
            parent_id: '',
            active: this.value
          }
        }).then((res) => {
          if (res.code == 200) {
            console.log('调取启用中的仓库', res)
            this.qi_CangKu = res.body.data
            this.CebianCangKu = res.body.data
            // 面包屑还原
            this.mianbaoxie_list = [{
              site_name: '初始',
              site_id: ''
            }]
          }
        })
      } else {
        // 查询停用仓库
        query_stop_store_house_list({
          data: {
            ent_id: self.$store.state.ent_id
          }
        }).then(res => {
          console.log('调取可以停用的仓库', res)
          if (res.code == 200) {
            this.qi_CangKu = res.body.data
            // 面包屑还原
            this.mianbaoxie_list = [{
              site_name: '初始',
              site_id: ''
            }]
          } else if (res.code == 500) {
          }
        })
      }
    },
    quanbu() {
      const self = this
      // get_prod_list_like({data:{
      //   ent_id:self.$store.state.ent_id,
      //   prod_name:"",
      //   active: '1',
      // }}).then((res)=>{
      //   console.log(res);
      //   if (res.code==10031) {
      //     this.tableData =res.body.data
      //   }
      // })
      query_prod_info_list({
        data: {
          ent_id: this.$store.state.ent_id,
          active: '1'
        }
      }).then((res) => {
        console.log('===菜品数据---获取菜品列表', res)
        const date = JSON.parse(JSON.stringify(res.body.data))
        this.tableData = date
      })
    },
    // 获取准备停用的仓库--site_id
    handleCheckedCitiesChange(checkedVal) {
      console.log(this.checkList, '获取准备停用的仓库获取仓库id', checkedVal)
      // 停用的仓库 site_id 数组
      this.cangku_id_Str = checkedVal.join(',')
      console.log('list', this.cangku_id_Str)
    },

    toggleSelection(rows) {
      // 没用着
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    // 面包屑导航
    mianbaoxie(item, index) {
      // console.log(item,index);
      console.log(this.mianbaoxie_list.length, '面包屑', item, index)
      this.mianbaoxie_list.splice(index + 1, this.mianbaoxie_list.length - (index + 1))
      this.chaxunzicangku(item.site_id)
      // 在初始层调取全部的菜品信息
      if (item.site_name == '初始') {
        this.quanbu()
      } else {
        this.mianbaoxieChaCangKu(item.site_id)
      }
    },
    // 侧边按的面包屑导航
    mianbaoCe(item, index) {
      console.log(this.mianbao_listCe.length, '面包屑', item, index)
      this.mianbao_listCe.splice(index + 1, this.mianbao_listCe.length - (index + 1))
      this.chaxunzicangku(item.site_id)
    },
    // 面包屑查询 当前仓库的菜品
    mianbaoxieChaCangKu(site_id) {
      const self = this
      query_prod_of_store_house_list({
        data: {
          ent_id: self.$store.state.ent_id,
          site_id: site_id
        }
      }).then((res) => {
        console.log('res===查询', res)
        if (res.code == 200) {
          this.tableData = res.body.data
        }
      })
    },

    // 显示的菜品多选框的选中按钮
    handleSelectionChange(val) {
      // console.log('菜品的多选框',val); // 数组格式
      this.multipleSelection = val
    },
    // 调整仓库进行单选或多选
    handleCheckedCitiesChange_TiaoZhengCangKu(TiaoZhengCangKu_Value) {
      // console.log('this.radio_CangKu',this.radio_CangKu);
      this.radio_CangKu = {
        site_name: [],
        site_id: []
      }
      this.radio_CangKu.site_id = TiaoZhengCangKu_Value
      this.CebianCangKu.forEach((item, index) => {
        TiaoZhengCangKu_Value.forEach((itemA) => {
          if (item.site_id == itemA) {
            const xianzhong_site_name = item.site_name
            this.radio_CangKu.site_name.push(xianzhong_site_name)
          }
        })
      })
      console.log(this.radio_CangKu.site_name, '调整仓库方法', this.radio_CangKu)
    },
    // 调整仓库下方的选中按钮
    role_confirm() {
      const self = this
      this.drawer = false
      // 整改菜品的仓库
      this.caipinXuanZhong = []
      this.multipleSelection.forEach((item) => {
        this.caipinXuanZhong.push(item.prod_id)
      })
      console.log(this.radio_CangKu, 'caipinXuanZhong选中的菜品id', this.caipinXuanZhong)
      const radio_CangKu_site_id = this.radio_CangKu.site_id
      const radio_CangKu_site_name = this.radio_CangKu.site_name
      // 调取到的仓库信息
      console.log('radio_CangKu_site_id', radio_CangKu_site_id, 'radio_CangKu_site_name', radio_CangKu_site_name)
      // // 修改菜品的仓库的属性   调整仓库
      batch_update_prod_store({
        data: {
          ent_id: self.$store.state.ent_id, // 企业id
          user_id: self.$store.state.user_id, // 用户id
          prod_id: this.caipinXuanZhong.join(','), // 产品id,逗号分割
          // prod_id:this.multipleSelection.join(','), // 产品id,逗号分割
          site_id: radio_CangKu_site_id.join(','), // 仓库id
          site_name: radio_CangKu_site_name.join(',') // 仓库名称
        }
      }).then((res) => {
        if (res.code == 200) {
          // console.log('修改菜品的仓库的属性成功',res);
          // this.qi_CangKu = []
          this.handle_ChaXunCangKu(this.site_id_item)

          this.$message({
            message: '菜品调整到新仓库成功',
            type: 'success'
          })
        }
      })
    },
    // 调整仓库下方的取消按钮
    role_cancel() {
      this.drawer = false
      this.checkListTiao = []
    },
    // 新增仓库按钮 调出新增仓库的侧边按
    add_ZengJiaCangKu() {
      this.add_drawer = true
    },
    // 侧边栏的新增仓库确认按钮
    add_NewCangKu() {
      const self = this
      // 1:新增 0:修改
      if (this.PanDuanCangKu_Shi_XiuGai == 1) {
        // 新增仓库  // 判断仓库名不可以是空的
        if (this.CaiPin_XinZeng_CangKu != '') {
          insert_store_house({
            data: {
              ent_id: self.$store.state.ent_id,
              user_id: self.$store.state.user_id,
              site_name: this.CaiPin_XinZeng_CangKu, // 产品仓库名称
              // parent_id: this.checkList.length == 1 ? this.checkList.join(',') : '', // 父id
              parent_id: null, // 父id
              child_flag: '0' // 是否有子节点，1是，0否
            }
          }).then((res) => {
            if (res.code == 200) {
              this.add_drawer = false // 关闭侧边栏
              this.CaiPin_XinZeng_CangKu = '', // 清空仓库的名称
              this.$message({
                message: '新增仓库成功',
                type: 'success'
              })
              this.JiChu_CangKuXinXi()
              // console.log('添加仓库的返回值',res);
            } else if (res.code == '1200' || res.code == '1100') {
              this.$message({
                message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                type: 'error'
              })
            }
          })
        }
      } else if (this.PanDuanCangKu_Shi_XiuGai == 0) {
        if (this.checkList.length < 2 && this.checkList.length != 0) {
          const checked_cangku_DanGe = this.qi_CangKu.filter((item) => {
            if (item.site_id == this.checkList) {
              return item
            }
          })
          // 拿到选中的复合条件的 返回值数组 数据
          // this.CaiPin_XinZeng_CangKu = checked_cangku_DanGe[0].site_name
          const site_id = checked_cangku_DanGe[0].site_id
          update_store_house({
            data: {
              ent_id: self.$store.state.ent_id,
              user_id: self.$store.state.user_id,
              site_id: site_id,
              site_name: this.CaiPin_XinZeng_CangKu,
              parent_id: '0',
              child_flag: '1'
            }
          }).then((res) => {
            console.log('修改仓库成功', res)
            if (res.code == 200) {
              this.$message({
                message: '新增仓库成功',
                type: 'success'
              })
              this.CaiPin_XinZeng_CangKu = '',
              this.add_drawer = false
              this.JiChu_CangKuXinXi()
            } else if (res.code =='1200'||res.code =='1100') {
              this.$message({
                message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                type: 'error'
              })
            }
          })
        }
      }
    },
    // 取消增新仓库
    QuXiaoAdd_NewCangKu() {
      this.CaiPin_XinZeng_CangKu = '',
      this.add_drawer = false
    },
    // 修改仓库名称
    handle_XiuGaiCangKuMing() {
      console.log('修改仓库名称')
      if (this.checkList.length < 2 && this.checkList.length != 0) {
      // 1:新增 0:修改
        this.PanDuanCangKu_Shi_XiuGai = '0'
        this.add_drawer = true
      } else {
        this.$message({
          message: '请选择一个仓库',
          type: 'success'
        })
      }
    },

    // 查询仓库 的 菜品信息
    handle_ChaXunCangKu(item, index) {
      console.log(item, index)
      // this.mianbaoxie_list=[]
      // let event = window.event||arguments[0];
      const self = this
      this.site_id_item = item
      query_prod_of_store_house_list({
        data: {
          ent_id: self.$store.state.ent_id,
          site_id: item.site_id
        }
      }).then((res) => {
        console.log(' 查询仓库 的 菜品信息', res)
        if (res.code == 200) {
          this.tableData = res.body.data
          // if (this.mianbaoxie_list.length != 0) {
          //   if (this.mianbaoxie_list[this.mianbaoxie_list.length - 1].site_id != item.site_id) {
          //     this.mianbaoxie_list.push({
          //       site_name: item.site_name,
          //       site_id: item.site_id
          //     })
          //   }
          // } else {
          //   this.mianbaoxie_list.push({
          //     site_name: item.site_name,
          //     site_id: item.site_id
          //   })
          // }
        }
      })
      // this.chaxunzicangku(item.site_id)
    },
    chaxunzicangku(site_id) {
      const self = this
      // 查询仓库  仓库下的子仓库
      query_store_house_list({
        data: {
          ent_id: self.$store.state.ent_id,
          parent_id: site_id,
          active: this.value
        }
      }).then((res) => {
        // console.log(res.body.data,'=======',res,'==========',this.mianbaoxie_list,'=====================',res);
        if (res.code == 200) {
          // if (res.body.data.length!=0) {
          if (this.drawer) {
            this.CebianCangKu = res.body.data
          } else {
            this.qi_CangKu = res.body.data
          }
          // }
        }
      })
    },
    // 调整仓库的侧边栏查询仓库
    handle_ChaXunCangKuA(item, index) {
      if (this.mianbao_listCe.length != 0) {
        if (this.mianbao_listCe[this.mianbao_listCe.length - 1].site_id != item.site_id) {
          this.mianbao_listCe.push({
            site_name: item.site_name,
            site_id: item.site_id
          })
        }
      } else {
        this.mianbao_listCe.push({
          site_name: item.site_name,
          site_id: item.site_id
        })
      }
      this.chaxunzicangku(item.site_id)
    },
    // 删除仓库
    handle_ShanChuCangKu() {
      const self = this
      // 选中的仓库id
      const site_ids = this.cangku_id_Str
      delete_store_house({
        data: {
          ent_id: self.$store.state.ent_id,
          user_id: self.$store.state.user_id,
          site_ids: site_ids
        }
      }).then((res) => {
        if (res.code == 200) {
          console.log('删除成功', res)
          this.$message({
            message: '删除仓库成功',
            type: 'success'
          })
          this.JiChu_CangKuXinXi() // 重新刷新接口
        }
      })
    },

    // 搜索
    searchAllCompany() {
      const self = this
      console.log('测试一下', this.product)
      // get_prod_list_like({data:{
      query_prod_info_list({
        data: {
          ent_id: self.$store.state.ent_id,
          // prod_name:this.product,
          search_str: this.product,
          active: '1'
        }
      }).then((res) => {
        console.log(res)
        if (res.code == 10139) {
          this.$message({
            message: '查询成功',
            type: 'success'
          })
          const tableData = res.body.data
          this.tableData = tableData
        } else {
          this.$message({
            message: '查询失败',
            type: 'success'
          })
        }
      })
    },

    // 停用仓库事件
    /**
 * cangku_id_Str 存放选中的仓库site_id的字符串
 */
    ting_qiyong() {
      const self = this
      const site_ids = this.cangku_id_Str
      const list = []
      console.log(this.cangku_id_Str)
      if (this.value == 1 && this.cangku_id_Str != '') {
        // 启用页面停用
        store_house_active_stop({
          data: {
            ent_id: self.$store.state.ent_id,
            site_ids: site_ids
          }
        }).then(res => {
          console.log('启用页面停用', res)
          if (res.code == 200) {
            this.$message({
              message: '停用成功',
              type: 'success'
            })
            this.checkList = [] // 对选中的多选进行清空
            // this.mianbaoxie_list=[{
            //   site_name: '初始',
            //   site_id: ''
            // }]
            this.JiChu_CangKuXinXi()
          } else if (res.code == 12000) {
            this.$message({
              message: '仓库中还有菜品，不能停用',
              type: 'warning'
            })
          }
        })
      } else if (this.value == 2 && this.cangku_id_Str != '') {
        // 仓库进行启用
        store_house_active_start({
          data: {
            site_ids: site_ids,
            ent_id: self.$store.state.ent_id
          }
        }).then(res => {
          console.log('启用页面启用', res)
          if (res.code == 200) {
            this.$message({
              message: '启用成功',
              type: 'success'
            })
            this.checkList = [] // 对选中的多选进行清空
            this.JiChu_CangKuXinXi()
          }
        })
      } else {
        this.$message({
          message: '请选择停用仓库',
          type: 'warning'
        })
      }
    }

  }
}
</script>

<style scoed lang="scss">
.el-main{
  // height: 1000px;
  background: #F6F6FA;
}
.Centrepot{
  width: 100%;
  // height: 1200px;
  // padding-bottom: 100px;
  .Enterprise-p{
    font-size: 18px;
    color: #999999;
    display: flex;
    margin: 26px 0 30px 0;
    span{
      margin: 4px;
    }
    i{
      background: url('../../../assets/img/din.png') no-repeat;
      width: 23px;
      height: 30px;
      display: block;
      margin-right: 11px;
    }
  }
  .Centrepot-box{
    display: flex;
      // .adjust{
      //   background-color: #FC4353;
      //   .el-checkbox-group{
      //     background-color: #FC4353;
      //       // max-height: 600px;
      //       // /* border: 1px solid; */
      //       // /* height: 600px; */
      //       // /* overflow: hidden; */
      //       // overflow-y: scroll;
      //   }
      // }
  }
  .Centrepot-box-left{
      background: #ffffff;
      width: 20%;
      display: block;
      padding: 22px;
      box-sizing: border-box;
      margin: 0px auto;
      position: relative;
      .cangku_name{
        // height: 440px;
        height: 360px;
        overflow: hidden;
        overflow-y: scroll;
        /*滚动条样式*/
        &::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 4px;
          /*高宽分别对应横竖滚动条的尺寸*/
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块样式*/
          border-radius: 5px;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: #999999;
        }

        &::-webkit-scrollbar-track {
          /*滚动条里面轨道样式*/
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          border-radius: 0;
          background: rgba(0, 0, 0, 0.1);
        }
        &>div{
          &>label{
            &:first-child{
              margin-top: 20px;
            }
            margin-top: 50px;
          }
        }
      }
      .el-checkbox{
        margin-top: 0px;
      }
      .Centrepot-left{
        display: flex;
        justify-content: space-between;
        margin: 0px 0px 15px 0px;
      }
      .span1{
          width: 70px;
          height: 40px;
          line-height: 40px;
          display: block;
          color: #333333;
          font-size: 16px;
          text-align: center;
          border: 1px solid #CCCCCC;
          border-radius: 10px;
          &:hover{
            cursor: pointer;
          }
        }
      .qi_CangKu_boxs{
        height: 100px;
        line-height: 100px;
        font-size: 16px;
      }
  }
  .Centrepot-box-right{
      margin-left: 20px;
      width: 80%;
      height: 600px;
      overflow-y: auto;
      // height: 855px;
      background: #FFFFFF;
      border-radius: 10px;
      .Centrepot-box-a{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 22px 31px;
        box-sizing: border-box;
        .GongNengAnNiu{
          display: flex;
          justify-content: space-between;
        }
        a{
          margin-left: 20px;
          width: 103px;
          height: 40px;
          display: block;
          color: #fff;
          text-align: center;
          font-size: 16px;
          line-height: 40px;
          background: #FC4353;
          border-radius: 10px;
          &:hover{
            cursor: pointer;
          }
        }
      }
  }
  .Centrepot-newly{
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%);
    .xinzeng_span{
      font-size: 16px;
      text-align: center;
      width: 100px;
      height: 36px;
      line-height: 36px;
      background: #FC4353;
      border-radius: 10px;
      color: #fff;
      margin: 0 auto;

    }
  }
  .liebiao{
    // width:325px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .dd1{
      span{
        color: #FC4353;
      }
    }
    .dd2{
      flex: 1;
    }
        li{
            display: flex;
            height:30px;
            justify-content: space-between;
            align-items: center;
            color:#1A2533;
            font-size: 18px;
            padding-left: 10px;
            cursor:pointer;
            // &:hover{
            //     background: #eee;
            // }
            .img1{
                height:11px;
                width:20px;
            }
            input{
                -webkit-appearance: none;
                width: 90%;
                height: 36px;
                display: inline-block;
                margin: -3px 5px 0 0!important;
                cursor: pointer;
                vertical-align: middle;
                // background:url(../../assets/noccc.png) no-repeat;
                // &:checked {
                //     // background: url(../../assets/okblue.png) no-repeat;
                // }
                outline-color:none;
                outline-style :none;
                outline-width:none;
            }
        }
}

.el-radio-group{
  display: flex;
  flex-direction: column;
  .el-radio{
    margin-top: 30px;
    .el-radio__input{
      transform:scale(1.1);
    }
    .el-radio__label{
      font-size: 16px;
    }
  }
}

}

</style>
<style lang='scss'>
    // .Centrepot-box{
    //   .Centrepot_lest{

    //   }
    //   .el-breadcrumb{
    //     display: flex;
    // width: 300px;
    // overflow-x: scroll;
    // overflow-y: hidden;
    //   }
    //   .el-breadcrumb__item{
    //         width: 115px;
    //       display: block;
    //       float: none;
    //   }
    // }
</style>
